import { errorMessage } from '@/utils/publicMethods';
import { onUnmounted, ref } from 'vue';

export default function useTimer() {
  let Timer: any = null;
  const timeStatus = ref(true);
  const startTimer = () => {
    if (timeStatus.value === true) {
      timeStatus.value = false;
      Timer = setTimeout(() => {
        timeStatus.value = true;
        clearTimeout(Timer);
        Timer = null;
        console.log('计时器完成');
      }, 30000);
      return true;
    } else {
      errorMessage('导出的点击间隔请勿小于30秒');
      throw new Error('导出的点击间隔请勿小于30秒');
    }
  };
  onUnmounted(() => {
    clearTimeout(Timer);
    Timer = null;
  });
  return {
    timeStatus,
    startTimer,
  };
}
