import { ErpTableOptions } from '@/types/type';
import { showFiles } from '@/utils/publicMethods';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '日期',
      prop: 'statisticsDate',
      type: 'time',
      value: [],
      filterProp: ['statisticsDateStart', 'statisticsDateEnd'],
    },
    {
      label: '报表名称',
      prop: 'reportName',
      value: '',
      type: 'input',
    },
    {
      label: '报表生成时间',
      prop: 'createdTime',
      value: [],
      type: 'time',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 235,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button class=""  type="primary"
            plain
            v-if="hasPermission('erp:dailyreport:exportAssertReport')"
            @click='addContract(row,1)'>导出基础版</el-button>
            <el-button class=""  type="primary"
            plain
            v-if="hasPermission('erp:dailyreport:exportAssertReportHighLevel')"
            @click='addContract(row,2)'>导出高级版</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const addContract = (row, type) => {
            let link = null as any;
            if (type === 1) {
              link = row.excelUrl;
            } else {
              link = row.highLevelExcelUrl;
            }
            showFiles(row.excelName, link, false);
          };
          return { addContract };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
